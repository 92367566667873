import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, OrthographicCamera } from '@react-three/drei';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReactAudioPlayer from 'react-audio-player';

import createAnimation from './converter';
import blinkData from './blendDataBlink.json';

import * as THREE from 'three';
import axios from 'axios';
const _ = require('lodash');

const textToVoiceAPI = 'https://backend.airtab.us'
const virtualMeAPI = 'https://vm.airtab.us'
const appSlug = 'airdec'

function Avatar({ avatar_url, speak, setSpeak, text, setText, setAudioSource, playing }) {

  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryFace = null;
  let morphTargetDictionaryEyeLeft = null;
  let morphTargetDictionaryEyeRight = null;
  let morphTargetDictionaryEyebrow = null;
  let morphTargetDictionaryEyelash = null;
  let morphTargetDictionaryLowerTeeth = null;

  
  gltf.scene.traverse(node => {


    if(node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {


      // console.log(node.name);

      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;

      if (node.name.includes("Face")) {
        morphTargetDictionaryFace = node.morphTargetDictionary;
      }
      else if (node.name.includes("Eye_L")) {
        morphTargetDictionaryEyeLeft = node.morphTargetDictionary;
      }
      else if (node.name.includes("Eye_R")) {
        morphTargetDictionaryEyeRight = node.morphTargetDictionary;
      }
      else if (node.name.includes("Eyebrows")) {
        morphTargetDictionaryEyebrow = node.morphTargetDictionary;
      }
      else if (node.name.includes("Eyelashes")) {
        morphTargetDictionaryEyelash = node.morphTargetDictionary;
      }
      else if (node.name.includes("TeethLower002")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }

    }

  });

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {

    if (speak === false)
      return;

      console.log('2');

    makeSpeech(text)
    .then( response => {

      console.log('4');

      let {blendData, filename} = response.data;

      // console.log(blendData);
      // console.log(morphTargetDictionaryFace);
      // console.log(morphTargetDictionaryEyeLeft);
      // console.log(morphTargetDictionaryEyeRight);
      // console.log(morphTargetDictionaryLowerTeeth);

      let newClips = [
        createAnimation(blendData, morphTargetDictionaryFace, 'Face002'),
        createAnimation(blendData, morphTargetDictionaryFace, 'Face002_1'),
        createAnimation(blendData, morphTargetDictionaryEyeLeft, 'Eye_L002'),
        createAnimation(blendData, morphTargetDictionaryEyeLeft, 'Eye_L002_1'),
        createAnimation(blendData, morphTargetDictionaryEyeLeft, 'Eye_L002_2'),
        createAnimation(blendData, morphTargetDictionaryEyeRight, 'Eye_R002'),
        createAnimation(blendData, morphTargetDictionaryEyeRight, 'Eye_R002_1'),
        createAnimation(blendData, morphTargetDictionaryEyeRight, 'Eye_R002_2'),
        createAnimation(blendData, morphTargetDictionaryEyebrow, 'Eyebrows'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes'),
        createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'TeethLower002'),
        createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'TeethLower002_1')
      ];

      console.log(blendData);
      console.log(filename);
      console.log('5');

        
      filename = textToVoiceAPI + filename;
        
      setClips(newClips);
      // setTimeout(setAudioSource(filename), 1000);
      setAudioSource(filename);

    })
    .catch(err => {
      console.error(err);
      setSpeak(false);

    })

  }, [speak]);

  // let idleFbx = useFBX('/idle.fbx');
  // let { clips: idleClips } = useAnimations(idleFbx.animations);

  // idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
  //   return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  // });

  // idleClips[0].tracks = _.map(idleClips[0].tracks, track => {

  //   if (track.name.includes("Head")) {
  //     track.name = "head.quaternion";
  //   }

  //   if (track.name.includes("Neck")) {
  //     track.name = "neck.quaternion";
  //   }

  //   if (track.name.includes("Spine")) {
  //     track.name = "spine2.quaternion";
  //   }

  //   return track;

  // });

  useEffect(() => {

    // let idleClipAction = mixer.clipAction(idleClips[0]);
    // idleClipAction.play();

    let blinkFace = createAnimation(blinkData, morphTargetDictionaryFace, 'Face002');
    let blinkFaceAction = mixer.clipAction(blinkFace);
    blinkFaceAction.play();

    let blinkEyelashes = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes');
    let blinkEyelashesAction = mixer.clipAction(blinkEyelashes);
    blinkEyelashesAction.play();


  }, []);

  // Play animation clips when available
  useEffect(() => {

    if (playing === false)
      return;
    
    _.each(clips, clip => {
        let clipAction = mixer.clipAction(clip);
        clipAction.setLoop(THREE.LoopOnce);
        clipAction.play();

    });

  }, [playing]);


  
  // useEffect(() => {
  //   setText(`Hi, my name is Dr. Ali.`);
  //   setSpeak(true);
  // }, []);
  
  useFrame((state, delta) => {
    mixer.update(delta);
  });


  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}


function makeSpeech(text) {
  // const fetchData = async () => {
  //   const response = await axios.post(textToVoiceAPI + '/talk/', { 'text': text });
  //   return response.data;
  // }
  return axios.post(textToVoiceAPI + '/talk/', { 'text': text, 'voice': 'en-US-JasonNeural' });
}

const STYLES = {
  area: {position: 'absolute', top:'10px', left: '10px', zIndex: 500},
  text: {margin: '0px', width:'400px', borderRadius: '10px', padding: '5px', background: 'rgba(255,255,255,.8)', color: '#000', fontSize: '1.2em', border: 'none'},
  disabled: {opacity: 0.5},
  speak: {padding: '10px 20px', marginTop: '5px', display: 'block', color: '#fff', background: '#333', border: 'None', borderRadius: '100px', textDecoration: 'none'},
  area2: {position: 'absolute', top:'5px', right: '15px', zIndex: 500},
  label: {color: '#777777', fontSize:'0.8em'},
  classic: {position: 'absolute', bottom:'10px', right: '10px', zIndex: 500},
  init: {position: 'absolute', top:'10px', left: '50%', transform: 'translate(-50%)', zIndex: 500},
  introduce: {padding: '20px 40px 20px 40px', display: 'block', color: '#fff', borderRadius: '100px',  textDecoration: 'none', fontWeight: 700, fontSize: '18px', textTransform: 'uppercase', background: 'linear-gradient(60deg, #16aef3, #00bcd4)', border: 'None'},
  chatHistory: {width:'400px', padding: '10px', borderRadius: '10px', background: 'rgba(255,255,255,.8)', whiteSpace: 'pre-line', height: '200px', opacity: '0.8', overflow: 'auto'},
  chatHistoryWrapper: {position: 'absolute', bottom:'10px', left: '10px', zIndex: 500},
  logo: {position: 'absolute', top: '10px', right: '10px', zIndex: 500},
  logoImg: {maxWidth: '200px', minWidth: '150px'}
}

function App() {

  const audioPlayer = useRef();
  const textLog = useRef();

  const [speak, setSpeak] = useState(false);
  const [text, setText] = useState('');
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [uuid, setUuid] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [visible, setVisible] = useState(true);
  const [chatArray, setChatArray] = useState([]);

  const logoSrc = 'images/logos/airdec.png'


  function initSpeech() {
    setVisible((prev) => !prev);
    setText(`Hi, my name is Dr. Ali Beheshti. I am the owner and CEO of Airdec, a family-owned technology company located in Great Falls, Virginia. Our mission is to provide better, simpler, and more affordable IT solutions leveraging artificial intelligence to improve your business operations.
    At Airdec, we have been assisting companies for several years, both locally and internationally, to implement the best technology for their needs and budget. Our team keeps up with the latest advancements and truly understands the challenges you face. We are even willing to share risks with clients to deliver the right solutions.
    I would be happy to elaborate further about Airdec's specific capabilities, products, team, and contact information. Please let me know how I can help you leverage technological innovation to drive meaningful growth for your business. I look forward to learning about your needs and challenges so we can collaborate effectively.
    If you would like me to communicate in other languages, please let me know which language you would prefer me to respond in. I'm happy to make efforts to communicate effectively based on your needs. To better address you may you provide your name?
    I look forward to learning more about how I can assist you and your business Airdec.`);
    // setText(`Hi, my name is Dr. Ali Beheshti.`);
    setSpeak(true);
  }

  function ask() {
    const fetchData = async () => {
      setFetching(true);
      const response = await axios.post(virtualMeAPI + '/chat/',
        {
          'app_slug': appSlug,
          'message': text,
          'uuid': uuid
        }
      );

      setChatArray(oldArray => [...oldArray, 'You: ' + text + '\n\n']);
      textLog.current.scrollTop = textLog.current.scrollHeight;
      
      setText(response.data.respond.answer);
      setSpeak(true);
      setFetching(false);
    }
    fetchData().catch(console.error);
  }

  // End of play
  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
  }

  // Player is read
  function playerReady(e) {
    // const newChat = () => {
    //   return React.createElement(
    //     "div",
    //     {style:{color:"red"}, id: 'someId', className: "someClass"},
    //     "Here I am",
    //   );
    // };
    setChatArray(oldArray => [...oldArray, 'AI: ' + text + '\n\n']);
    textLog.current.scrollTop = textLog.current.scrollHeight;
    setText('');
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);
  }

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const response = await axios.get(virtualMeAPI + '/get-app/' + appSlug + '/');
      setUuid(response.data.app.uuid);
    }
  
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [])

  return (
    <div className="full">
      <div style={STYLES.area}>
        <textarea placeholder='Type your Question/Response here...' rows={10} maxLength={500} type="text" style={STYLES.text} value={text} onChange={(e) => setText(e.target.value)} disabled={fetching} />
        <button onClick={() => ask()} style={STYLES.speak}  disabled={speak || fetching}> { fetching ? 'Wait for Answer...' : speak ? 'Recording & Running...' : 'Send ' + 'd' }</button>
      </div>

      {visible && (
        <div style={STYLES.init}>
          <button onClick={() => initSpeech()} style={STYLES.introduce}>Start</button>
        </div>
      )}

      <div style={STYLES.classic}>
        <a target='_blank' href="https://airdec.net" style={STYLES.speak}>Classic Website</a>
      </div>

      <div style={STYLES.chatHistoryWrapper}>
        <h3 style={{textAlign: 'left'}}>Chat Logs</h3>
        <div ref={textLog} style={STYLES.chatHistory}>{chatArray}</div> 
      </div>

      <div style={STYLES.logo}>
        <img src={logoSrc} style={STYLES.logoImg} />
      </div>

      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlay={playerReady}
        
      />
      
      {/* <Stats /> */}
    <Canvas dpr={0.75} onCreated={(ctx) => {
        ctx.gl.physicallyCorrectLights = true;
      }}>

    {/* <ambientLight /> */}
    {/* <pointLight position={[0, 0, 20]} /> */}
    {/* <pointLight position={[-10, -10, 0]} /> */}
    <pointLight position={[5, 3, 10]} intensity={20} />
    <pointLight position={[-5, 3, 10]} intensity={20} />

      <OrthographicCamera 
      makeDefault
      zoom={1500}
      position={[0, 1.65, 1]}
      />

      {/* <OrbitControls
        target={[0, 1.65, 0]}
      /> */}

      {/* <Suspense fallback={null}>
        <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
      </Suspense> */}

      <Suspense fallback={null}>
        <Bg />
      </Suspense>

      <Suspense fallback={null}>
          <Avatar 
            avatar_url="glb/avatar-vm-test-9.glb"
            speak={speak}
            setSpeak={setSpeak}
            text={text}
            setText={setText}
            setAudioSource={setAudioSource}
            playing={playing}
            />
      </Suspense>
  </Canvas>
  <Loader dataInterpolation={(p) => `Loading... please wait`}  />
  </div>
  )
}

function Bg() {
  
  const texture = useTexture('/images/bg.jpg');

  return(
    <mesh position={[0, 1.8, -1]} scale={[1.8, 1.2, 1]}>
      <planeBufferGeometry />
      <meshBasicMaterial map={texture} />

    </mesh>
  )

}

export default App;
